import React, { useState, useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import GeneralContext from "../GeneralContext/GeneralContext";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Paper from "@mui/material/Paper";

import Alert from "@mui/material/Alert";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";

const UUID = () => {
  useAxiosPrivate();
  const { pageTitle, setPageTitle } = useContext(GeneralContext);

  const [uuid, setUuid] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setError(false);
    setLoading(true);
    event.preventDefault();
    if (!uuid) {
      alert("Necesitas un uuid válido");
      return;
    }

    axios
      .getUUID(uuid)
      .then((response) => {
        setResponseData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al traer datos", error);
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    setPageTitle("UUID");
  }, [pageTitle, setPageTitle]);

  return (
    <PageBody>
      <PageBgBlock pad="3rem 6rem">
        <Typography variant="h1" align="center" sx={{ marginBottom: "1.2rem" }}>
          ESTADO UUID
        </Typography>

        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <Box
            justifyContent="center"
            sx={{ maxWidth: "80%", margin: "0 auto", display: "flex" }}
          >
            <Typography
              variant="body"
              align="center"
              sx={{ marginBottom: "40px" }}
            >
              Introduce el UUID tal como lo recibiste desde el webservice. Aquí podrás obtener información detallada sobre su trazabilidad y los eventos que ocurrieron a lo largo de su proceso.
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            <FormControl
              sx={{
                m: 1,
                minWidth: "100%",
                maxWidth: "100%",
                marginBottom: "39px",
              }}
            >
              <TextField
                required
                id="outlined-required"
                label="Ingresa UUID"
                size="small"
                value={uuid}
                onChange={(e) => setUuid(e.target.value)}
              />
            </FormControl>
            <PageButtonBlack loading={loading} width="312px">
              Traer Datos
            </PageButtonBlack>
          </form>

          {responseData && (
            <Box style={{ marginTop: "39px" }}>
              <Paper
                elevation={0}
                sx={{
                  padding: "10px 20px",
                  backgroundColor: "#E4E4E4",
                  borderRadius: "10px",
                  fontSize: "16px",
                }}
              >
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {JSON.stringify(responseData, null, 2)}
                </pre>
              </Paper>
            </Box>
          )}

          {error && (
            <Box style={{ marginTop: "39px" }}>
              <Alert variant="outlined" severity="error">
                <Box
                  justifyContent="center"
                  sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                >
                  <Typography variant="body" align="left">
                    UUID no encontrado.
                  </Typography>
                </Box>
              </Alert>
            </Box>
          )}
        </Box>
      </PageBgBlock>
    </PageBody>
  );
};

export default UUID;
