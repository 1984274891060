import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

import Alert from '@mui/material/Alert';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";

import ModalStandard from "../GeneralComponents/ModalStandard";
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InputRut from "../GeneralComponents/InputRut";
import {  Button, InputAdornment, } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import FileDownload from '@mui/icons-material/FileDownload';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import HourglassLoader from "../GeneralComponents/HourglassLoader";
import InputFake from "../GeneralComponents/InputFake";
import ApelacionHiddenPreview from "./ApelacionHiddenPreview";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_VAL_MINEDUC} from "../../Constants/Constants";

const buttonStyles = {
  backgroundColor: 'rgba(25, 118, 210, 0.04)',
  color: '#003B8D',
  border: 'none',
  textTransform: 'none',
  borderRadius: '4px 4px',
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
  fontWeight: '400',
  fontSize: '16px',
  boxShadow: 'none',
};

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ApelacionValidacion() {
  useAxiosPrivate();
  const { pageTitle, setPageTitle, setDrawerHidding, userSesion, includeRol } =  useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [documentVisible, setDocumentVisible] = useState(true);

    const [submitConfirm, setSubmitConfirm] = useState(false);
  const [canSign, setCanSign] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const location = useLocation();
  const { id } = useParams();
  const links = [
    { name: 'Inicio', path: '/home' },
    { name: 'Listado apelaciones', path: '/apelaciones_lista' },
    { name: 'Validación apelación', path: '/apelacion_validacion/'+id },
  ];
  const [estado, setEstado] = useState("");

   const [formData, setFormData] = useState({
    id_operador: "",
    operator_name: "",
    id_zona: "",
    periodo: "",
    description: "",
    evidence: "",
  });

  const PathSegment = () => {
    const location = useLocation();
    const segments = location.pathname.split('/').filter(Boolean);
    let path = segments.length > 1 ? segments[segments.length - 2] : null;
    path = path.startsWith("apelacion") ? "apelacion" : "devengo";
    return path;
  };

  const type = PathSegment();

const fetchData = async (query) => {
  setLoading(true);
  try {
    const api_get = type === "apelacion" ? axios.getApelacion(id) : axios.getDevengo(id);

    const response = await api_get;

    setFormData(response.data.formulario_datos);
    setEstado(response.data.estado);
    setDocumentVisible(true);
  } catch (error) {
    setDocumentVisible(false);
    console.error("Error al obtener los datos:", error);
  } finally {
    setLoading(false);
  }
};


  function singDoc() {
    //event.preventDefault();
        if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm)
        return;
    }

    setLoadingButton(true);
    const form = new FormData();
    const localFormData = {
        ...formData
    }

    form.append("data", JSON.stringify(localFormData));
        axios
      .signDoc(type,id, form)
      .then((response) => {
          setModalError(false);
          setModalContent("Documento enviado y firmado");
          setOpen(true);
      })
      .catch((error) => {
          setModalError(true);
          setModalError("La firma del documento a fallado");
      })
      .finally(() => {
       setLoadingButton(false);
        setSubmitConfirm(false);
      });

  }

    function rejectDoc() {
    //event.preventDefault();
    if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm)
        return;
    }
    setLoadingButton(true);
    const form = new FormData();
    const localFormData = {
        ...formData
    }

    form.append("data", JSON.stringify(localFormData));
    axios
      .rejectDoc(type,id, form)
      .then((response) => {
        navigate("/apelaciones_lista");
      })
      .catch((error) => {})
      .finally(() => {
       setLoadingButton(false);
        setSubmitConfirm(false);
      });
  }



  useEffect(() => {
    fetchData();
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);


   useEffect(() => {    
    if (includeRol([ROL_VAL_SUBTEL]) && estado === "En Proceso"){
      setCanSign(true);
    }
  }, [userSesion, formData]);

  if (pageLoaded) {
    return (
        <>
          <PageBody>
          <BreadcrumbStandard links={links} />
        {loading ? (
        <PageBgBlock pad="2rem 9rem">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
        </PageBgBlock>

        ) :  loadingButton ? (<HourglassLoader/> ) :
     (
      documentVisible && canSign ? 


      <>
      <PageBgBlock pad="0" >
      <Box sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}> 
        
        <ApelacionHiddenPreview formData={formData}  id={id} type={type}/>
          <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, marginBottom: 2 }}
            >
              JUSTIFICACIÓN DE SUBTEL
            </Typography>
          <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Escriba aquí la justificación"
                size="small"
                value={formData.comentario}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    comentario: event.target.value,
                  })
                }
              />
            </FormControl>
      
          
          </Box>


          <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "3rem" }}>
                    <Grid container spacing={2} sx={{paddingTop: '2rem'}}>

            {submitConfirm ?
                                <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{paddingBottom: "3rem"}} >
                                    <Alert icon={false} variant="filled" severity="warning" display="flex" justifyContent="center" sx={{ display: "flex", justifyContent: "center", '& .MuiAlert-message': { textAlign: "center", width: "inherit" } }}>
                                        <Typography
                                            variant="body"
                                            color="inherit"
                                            sx={{ textAlign: "center" }}
                                        >
                                    Atención: Al presionar ‘Aceptar’, aprueba la apelación de la prefactura, aplicando las correcciones en la pre-factura de SAGEC y generando un código de pre-factura, que podrá revisar en la página de listado de validaciones. Si presiona ‘Rechazar’, no se aplicarán las correcciones, pero igualmente se generará el código.                                        </Typography>
                                    </Alert>
                                </Grid>
                                :
                                ""
                            }                    
            <Grid size={2}></Grid>

            <Grid size={4}>
              <PageButtonWhite onClick={rejectDoc} loading={loadingButton} width="12rem"> Rechazar</PageButtonWhite>
            </Grid>
            <Grid size={4}>
              <PageButtonBlack onClick={singDoc} loading={ loadingButton} justifyContent="center" width="12rem">
                Aceptar
              </PageButtonBlack>
            </Grid>
            <Grid size={2}></Grid>
          </Grid>
          </Stack>
      </PageBgBlock>
      </>
       :

        <PageBgBlock pad="4rem 9rem">

            <Box align="center">
              <ErrorOutlineIcon color="error" sx={{ fontSize: 45 }} />
            </Box>
            <Typography
            align="center"
              variant="h1"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
            >
              No disponible
            </Typography>

            <Box
              justifyContent="center"
              sx={{ maxWidth: "500px", margin: "0 auto", display: "flex" }}
            >
              <Typography
                variant="body"
                align="center"
                sx={{ marginBottom: "20px" }}
              >
                El documento al que intenta acceder no esta diponible para ser firmado.
              </Typography>
            </Box>
            </PageBgBlock>
      
    )}
    <ModalStandard open={open} error={modalError} title={modalContent} btnText="Volver a listado" onClick={() => navigate("/apelaciones_lista")} onClose={handleClose}></ModalStandard>
      
    </PageBody>
              </>
    );
  }
}
