import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Button, Paper, Checkbox } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Download as DownloadIcon } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PageBody from "../GeneralComponents/PageBody";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ListEmpty from "../GeneralComponents/ListEmpty";
import * as XLSX from "xlsx";
import Grid from "@mui/material/Grid2";

import {ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_OP_ADMIN, ROL_ADMIN} from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const columns = [
  {
    id: "checkbox",
    label: "",
    width: "5%",
    minWidth: "50px",
    align: "left",
  },
  { id: "rbd", label: "RBD", width: "12%", minWidth: "100px", align: "center" },
  { id: "Zona", label: "Zona", width: "12%", minWidth: "100px" },
  {
    id: "Nombre",
    label: "Nombre",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "operador",
    label: "Operador",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "Fecha_de_Alta",
    label: "Fecha de Alta",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "Fecha_de_Baja",
    label: "Fecha de Baja",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "estado",
    label: "Estado",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "concurso",
    label: "Convocatoria",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
];



export default function ParqueList() {
  useAxiosPrivate();
  const [rows, setRows] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const navigate = useNavigate();

  const fetchData = async (query) => {
    setLoadingTable(true);
    axios
      .getParque()
      .then((response) => {
        const modifiedData = response.data.map((row, index) => {
          return {
            ...row,
            estado: row["Estado"] === "Activo" ? "Alta" : "Baja" || "Desconocido",
            operador: row["Operador"] || "Desconocido",
            Fecha_de_Alta: row["Fecha_de_Alta"] ? row["Fecha_de_Alta"].split("T")[0] : "", 
            Fecha_de_Baja: row["Fecha_de_Baja"] ? row["Fecha_de_Baja"].split("T")[0] : "", 
            index: index,
            id: index,
          };
        });
        setRows(modifiedData);
        setTableData(modifiedData);
        console.log(modifiedData)
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTable(false);
      });

       const columnOrder = [
      "checkbox",
      "rbd",
      "Nombre",
      "operador",
      "concurso",
      "Zona",
      "Fecha_de_Alta",
      "Fecha_de_Baja",
      "estado",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );

}

const downloadXLS = () => {
  const selectedRows = tableData.filter((row) => selected.includes(row.index));

  if (selectedRows.length === 0) {
    alert("No hay filas seleccionadas para descargar.");
    return;
  }

  const filteredData = selectedRows.map((row) => ({
    rbd: row.rbd,
    Zona: row.Zona,
    operador: row.operador,
    Nombre: row.Nombre,
    Convocatoria: row.concurso,
    Fecha_de_Baja: row.Fecha_de_Baja,
    Fecha_de_Alta: row.Fecha_de_Alta,
    estado: row.estado,
  }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tabla");
    XLSX.writeFile(workbook, "tabla.xlsx");
  };

  useEffect(() => {
    fetchData("");
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rejectDocs = () => {
    alert(selected)
  };
  const aproveDocs = () => {
    alert(selected)
  };
  const downloadDocs = () => {
    alert(selected)
  };

  const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;
    setFilters(filterObj);
    if (value == null) {
      delete filterObj[name];
    }
    if (Object.keys(filterObj).length !== 0) {
      setTableData([
        ...rows.filter((item) =>
          Object.entries(filterObj).every(([key, value]) => item[key] === value)
        ),
      ]);
    } else {
      setTableData(rows);
    }

  };

      const downloadPDF = (url) => {
      setLoadingPDF(true);
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.href = blobUrl;
                a.download = 'download.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
                setLoadingPDF(false);
            })
            .catch((error) => {setLoadingPDF(false); console.error('Error al descargar el archivo:', error); window.open(url, '_blank'); });
    };

  const downloadReports = () => {
    setLoading(true);
    alert("Descargar consolidado");
    setLoading(false);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((n) => n.index);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleCheckboxClick = (index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (index) => selected.indexOf(index) !== -1;

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
    <PageBody>

      <Grid
        container
        spacing={1}
        sx={{ marginBottom: "28px", marginTop: "-1rem" }}
      >
        <Grid size={3}>
          <InputFilter
            data={rows}
            item={"rbd"}
            name={"RBD"}
            callback={filterRecords}
          />
        </Grid>
        <Grid size={3}>
          <InputFilter
            data={rows}
            item={"concurso"}
            name={"Convocatoria"}
            callback={filterRecords}
          />
        </Grid>
        <Grid size={3}>
          <InputFilter
            data={rows}
            item={"operador"}
            name={"Operador"}
            callback={filterRecords}
          />
        </Grid>
        <Grid  size={3}>
          <InputFilter
            data={rows}
            item={"estado"}
            name={"Estado"}
            callback={filterRecords}
          />
        </Grid>
      </Grid>

      {loadingTable ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Cargando...
          </Typography>
        </Box>
      ) : (
        <>
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              background: "#FFFFFF",
              borderRadius: "0.25rem",
              marginBottom: "0.75rem",
            }}
          >
            <Typography
              variant="h1"
              color="inherit"
              noWrap
              sx={{ textAlign: "center", padding: "0.5rem" }}
            >
             LISTADO ESTADO DE PARQUE
            </Typography>
          </Paper>

          <PageBgBlock pad="0">
            <Paper
              sx={{
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                background: "#FFFFFF",
                borderRadius: "0.25rem",
                marginBottom: "2rem",
              }}
            >
              <TableContainer >
                <Table size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {filteredColumns.map((column, index) => (
                        <TableCell
                          key={"CL" + index}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.id === "checkbox" ? (
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < tableData.length
                                }
                                checked={
                                  tableData.length > 0 &&
                                  selected.length === tableData.length
                                }
                                onChange={handleSelectAll}

                                inputProps={{ "aria-label": "select all" }}
                              />

                              {column.label}
                            </Box>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.index);

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={"TR" + index}
                            aria-checked={isItemSelected}
                            key={row.index}
                            selected={isItemSelected}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            {filteredColumns.map((column, index) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={"TC" + index}
                                  align={column.align}
                                >
                                  {column.id.startsWith("url") ? (
                                    <Button
                                      disabled={loadingPDF}
                                      onClick={(e) => { e.stopPropagation();
                                                  downloadPDF(value)
                                                }}
                                      key={`${column.id}.button.${index}`}
                                      variant="text"
                                      startIcon={
                                        <DownloadIcon
                                          key={`${column.id}.icon.${index}`}
                                          sx={{ color: "#111" }}
                                        />
                                      }
                                    >
                                      <Typography
                                        key={`${column.id}.typ.${index}`}
                                        sx={{
                                          color: "#111",
                                          textTransform: "capitalize",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Descargar
                                      </Typography>
                                    </Button>
                                  ) : column.id==="diff_action" ? (
                                  <Stack>
                          <Grid container spacing={1} >
                        <Grid size={6} sx={{display:'flex'}}>
                            <PageButtonWhite width="2.5rem" pad="0 1rem"
                              onClick={(e) => { 
                                e.stopPropagation();
                                alert("aceptar")
                              }}
                            >
                                Si
                            </PageButtonWhite>
                        </Grid>
                        <Grid size={6} sx={{display:'flex'}}>
                            <PageButtonWhite width="2.5rem" pad="0 1rem"
                              onClick={(e) => { 
                                e.stopPropagation();
                                navigate('/apelacion');
                              }} 
                            > No
                            </PageButtonWhite>
                        </Grid>
                        </Grid>
                        </Stack>
                ) : column.id === "checkbox" ? (
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      onClick={() =>
                                        handleCheckboxClick(row.index)
                                      }
                                      inputProps={{
                                        "aria-labelledby": row.index,
                                      }}
                                    />
                                  ) : column.id === "estado" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "no-wrap",
                                      }}
                                    >
                                      <FiberManualRecordIcon
                                        sx={{
                                          fontSize: "0.75rem",
                                          color:
                                            value === "Alta"
                                              ? "#41AE43"
                                              : "#FB3B3B",
                                          marginRight: "0.5rem",
                                        }}
                                      />

                                      
                                      {value}
                                    </div>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                      {tableData.length == 0 ? (<ListEmpty></ListEmpty>) : null}
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </PageBgBlock>

           <Grid container spacing={2} >
          <Grid size={8}>
           
          </Grid>
          <Grid size={4}>
            <PageButtonWhite
              onClick={downloadXLS}
              width="100%"
            >
              Descargar selección (.xls)
            </PageButtonWhite>
          </Grid>
        </Grid>

        </>
      )}

    </PageBody>
    </>
  );
}

