import React, { useState } from "react";
import { 
  Card, CardHeader, Avatar, Chip, Divider, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Button, Typography, Box, Stack
} from '@mui/material';
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from 'react-router-dom';
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import { Download as DownloadIcon } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

export default function PrefacturaCard({ data, type, fetch }) {
  useAxiosPrivate();
  const navigate = useNavigate();
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const saldoData = [
  { label: "SALDO MENSUAL:", value: "$000.000" },
  { label: "DIFERENCIAS:", value: "$000.000" },
  { label: "SALDO A FAVOR POR CONTINGENCIAS:", value: "$000.000" },
  { label: "SALDO POR DEVENGOS:", value: "$000.000" },
  { label: "Total:", value: "$000.000" },
];
  const downloadPDF = (url) => {
    setLoadingPDF(true);
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = blobUrl;
        a.download = 'download.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
        setLoadingPDF(false);
      })
      .catch((error) => {
        setLoadingPDF(false);
        console.error('Error al descargar el archivo:', error);
        window.open(url, '_blank');
      });
  };

  const getPreCode = async (query, periodo) => {
    setLoadingButton(true);
    axios
      .getCode(query)
      .then((response) => {
          fetch(periodo)
      })
      .catch((error) => {})
      .finally(() => {


        setLoadingButton(false);
      });

}



  return (
    <Card sx={{ maxWidth: 400, marginRight: 2, marginBottom: 2, padding: 1 }}>
      <CardHeader
  sx={{ padding: 1 }}
  avatar={
    <Avatar
      src={data.avatar}
      sx={{ bgcolor: 'primary.main', borderRadius: 2, width: 40, height: 40 }}
    />
  }
  title={
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="subtitle1" fontWeight="bold">{data.nombre_operador}</Typography>
      <Box sx={{marginTop: -0.5}}><PageButtonSimple
        width="auto"
        pad="0"
        fs="0.6875rem"
        onClick={(e) => {
          navigate("/prefactura_detalle/" + data.id_operador);
        }}
      >
        Ver detalle operador
      </PageButtonSimple></Box>
    </Box>
  }
  action={
    <Box display="flex" flexDirection="column" alignItems="flex-end" sx={{ padding: 1 }}>
      <Typography variant="subtitle1" fontWeight="bold">Periodo</Typography>
      <Typography variant="body2" color="textSecondary">{data.periodo}</Typography>
    </Box>
  }
/>


      <Divider sx={{ my: 1 }} />

      <Grid container spacing={0} justifyContent="space-between" alignItems="center">
  <Grid size={6}>
    <Chip label={data.diff} size="small" sx={{ backgroundColor: data.color, color: data.diferencia ? "white" : "" }} />
  </Grid>
  <Grid size={6} sx={{ textAlign: "right" }}>
    <Typography variant="body2" color="textSecondary" sx={{ display: "flex", justifyContent: "flex-end",  alignItems: "center",   }}>
      <span style={{ display: "flex", alignItems: "center", marginRight: 4, marginTop: 4, width: "100%", justifyContent: "flex-end", }}>
        <FiberManualRecordIcon
          sx={{
            fontSize: ".75rem",
            color:
              data.estado_validacion === "En Apelación"
              ? "#F9961E" : data.estado_validacion === "Pendiente"
                ? "#FFE263"
                : data.estado_validacion.startsWith("Rechazado")
                ? "#FB3B3B"
                : "#41AE43",
            marginRight: "0.5rem",
          }}
        />
        {data.estado_validacion}
      </span>
    </Typography>
  </Grid>
</Grid>


      <Divider sx={{ my: 2 }} />

      <Grid container spacing={0} justifyContent="space-between">
        <Grid size={6}>
          <PageButtonSimple

            disabled={!data.link_prefactura_sagec}
            width="10rem"
            pad="0.5rem"
            fs="0.6875rem"
            startIcon={<DownloadIcon />}
            onClick={(e) => {
              e.stopPropagation();
              downloadPDF(data.link_prefactura_sagec);
            }}
          >

            <Stack>
            <span>SAGEC prefactura</span>
            <span><b>{data.version ? "V"+data.version : ""}</b> - {data.fecha_prefactura}</span>
            </Stack>
            
          </PageButtonSimple>
        </Grid>
        <Grid size={6}>
          <PageButtonSimple
            disabled={!data.link_prefactura_operador}
            width="10rem"
            pad="0.5rem"
            fs="0.6875rem"
            startIcon={<DownloadIcon />}
            onClick={(e) => {
              e.stopPropagation();
              downloadPDF(data.link_prefactura_operador);
            }}
          >
            
            <Stack>
            <span>Operador prefactura</span>
            <span>{data.fecha_prefactura ? data.fecha_prefactura : "-"}</span>
            </Stack>
          </PageButtonSimple>
        </Grid>
      </Grid>



<Divider sx={{ my: 2 }} />
              <Table>
<TableBody>
  {saldoData.map((row, index) => (
    <TableRow key={index} sx={{ borderBottom: "none" }}>
      <TableCell 
        align="right" sx={{ borderBottom: "none", paddingY: 0  }}>
        <Typography
          variant="small"
          align="right"
          sx={{ fontWeight: index === saldoData.length - 1 ? "bold" : "normal" }}
        >
          {row.label}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        sx={{ borderBottom: "none", paddingY: 0 }}
      >
        <Typography
          variant="small"
          align="right"
          sx={{ fontWeight: index === saldoData.length - 1 ? "bold" : "normal" }}
        >
          {row.value}
        </Typography>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
</Table>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ padding: 1 }}>
      {type ? (
        <Typography variant="body2" align="center" color="textSecondary" sx={{ mb: 1 }}>
          <b>Código de aprobación prefactura: {data.codigo}</b>
        </Typography>) : null}
        <Typography variant="body2" align="center" color="textSecondary" sx={{ mb: 2 }}>
          Validador: {data.nombre_validador ? "-" : "-"}
        </Typography>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid size={type ? 6 : 12}>
            <PageButtonWhite
              loading={data.estado_validacion !== "En Apelación"}
              width="100%"
              pad="0.5rem 1rem"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/apelacion_validacion/" + data.id);
              }}
            >
              Ir a apelación
            </PageButtonWhite>
          </Grid>
          {type ? (
          <Grid size={6}>
            <PageButtonBlack
              loading={data.estado_validacion === "En Apelación" || data.estado_validacion === "Pendiente" || data.estado_validacion === "Finalizado"}
              width="100%"
              pad="0.5rem 1rem"
              onClick={(e) => {
                e.stopPropagation();
                getPreCode(data.id, data.periodo);
              }}
            >
              Generar código
            </PageButtonBlack>
          </Grid>) : null}
        </Grid>
      </Box>
    </Card>
  );
}
