import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio, RadioGroup } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Divider } from "@mui/material";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import HourglassLoader from "../GeneralComponents/HourglassLoader";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";

import ModalStandard from "../GeneralComponents/ModalStandard";

import PageError from "../GeneralComponents/PageError";
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";
import FusHiddenPreview from "./FusHiddenPreview";
import dayjs from "dayjs";
import "dayjs/locale/es";

import {
  REJECT_F,
  NO_EES,
  NO_EES_TEMP,
  NO_SUBTEL,
  NO_MINEDUC,
  ROL_REP_EES,
  ROL_REP_EES_TEMP,
  ROL_ADMIN_SUBTEL,
  ROL_VAL_SUBTEL,
  ROL_MINEDUC_ADMIN,
  QUESTIONS,
} from "../../Constants/Constants";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);



export default function FusFirma() {
  useAxiosPrivate();
  const { pageTitle, setPageTitle, setDrawerHidding, userSesion, includeRol } =
    useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [submitConfirm, setSubmitConfirm] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [techTypes, setTechTypes] = useState([]);
  const [showQuestions, setShowQuestions] = useState(true);
  const [answers, setAnswers] = useState(Array(QUESTIONS.length).fill("no"));
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [documentVisible, setDocumentVisible] = useState(true);
  const [canSign, setCanSign] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const { id } = useParams();
  const links = [
    { name: "Inicio", path: "/home" },
    { name: "Listado FUS", path: "/fus_lista" },
    { name: "Firma FUS", path: "/fus_firma/" + id },
  ];

  const [formData, setFormData] = useState({
    rut: userSesion.id,
    operator_name: "",
    rbd: "",
    estado: "",
    ees_name: "",
    ees_managers: ["", ""],
    ees_temporal_manager_list: [""],
    ees_temporal_manager: "",
    tech_id: "",
    int_speed_down: "",
    installation_date: dayjs(),
    speed_up: "",
    speed_down: "",
    latency: "",
    packet_loss: "",
    evidence: [],
    answers: [],
    rejectOption: null,
  });

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
    setFormData((prevFormData) => ({
      ...prevFormData,
      answers: updatedAnswers,
    }));
  };

  const handleClick = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      rejectOption: prevFormData.rejectOption === value ? null : value,
    }));
  };

  function getTechType() {
    axios
      .getTechTypes()
      .then((response) => {
        setTechTypes(response.data)
      })
      .catch((error) => {});
  }
  const fetchData = async (query) => {
    setLoading(true);
    axios
      .getFus(id)
      .then((response) => {
        setFormData({
          ...response.data.datos,
          installation_date: dayjs(response.data.datos["installation_date"]),
          estado: response.data.estado,
        });
        const flag =
          includeRol([ROL_REP_EES, ROL_REP_EES_TEMP]) &&
          (response.data.estado === NO_EES ||
            response.data.estado === NO_EES_TEMP);
        setShowQuestions(flag);
        setAnswers(Array(QUESTIONS.length).fill(flag ? "no" : "yes"));
      })
      .catch((error) => {
        setDocumentVisible(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function singDoc(event) {
    event.preventDefault();
    if (!submitConfirm) {
      setSubmitConfirm(!submitConfirm);
      return;
    }
    
    setLoadingButton(true);
    const form = new FormData();
    const localFormData = {
      ...formData,
      installation_date: formData["installation_date"]
        .locale("es")
        .format("YYYY/MM/DD HH:MM:ss")
        .toString(),
    };

    form.append("data", JSON.stringify(localFormData));
    axios
      .signDoc("fus", id, form)
      .then((response) => {
        setModalError(false);
        setModalContent("Documento enviado y firmado");
        setOpen(true);
      })
      .catch((error) => {
        setModalError(true);
        setModalError("La firma del documento a fallado");
      })
      .finally(() => {
        setLoadingButton(false);
        setSubmitConfirm(false);
      });
  }

  function rejectDoc(event) {
    event.preventDefault();
    if (!submitConfirm) {
      setSubmitConfirm(!submitConfirm);
      return;
    }
    setLoadingButton(true);
    const form = new FormData();
    const localFormData = {
      ...formData,
      installation_date: formData["installation_date"]
        .locale("es")
        .format("YYYY/MM/DD HH:MM:ss")
        .toString(),
    };

    form.append("data", JSON.stringify(localFormData));
    axios
      .rejectDoc("fus", id, form)
      .then((response) => {
        navigate("/fus_lista");
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingButton(false);
        setSubmitConfirm(false);
      });
  }

  useEffect(() => {
    setPageTitle(
      "Firma de formulario electrónico de Upgrade del Servicio (FUS)"
    );
    getTechType();
    fetchData();
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);
  useEffect(() => {
    setIsSubmitEnabled(answers.every((answer) => answer === "yes"));
    setFormData((prevFormData) => ({
      ...prevFormData,
      answers: answers, // Incluye las respuestas en formData
    }));
  }, [answers]);

  useEffect(() => {
    if (
      includeRol([ROL_REP_EES, ROL_REP_EES_TEMP]) &&
      (formData.estado === NO_EES || formData.estado === NO_EES_TEMP)
    ) {
      setCanSign(true);
    } else if (
      includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) &&
      formData.estado === NO_SUBTEL
    ) {
      setCanSign(true);
    } else if (
      includeRol([ROL_MINEDUC_ADMIN]) &&
      formData.estado === NO_MINEDUC
    ) {
      setCanSign(true);
    }
  }, [userSesion, formData]);

  if (pageLoaded) {
    return (
      <>
        <PageBody>
          <BreadcrumbStandard links={links} />

          {loading ? (
            <PageBgBlock pad="2rem 9rem">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <CircularProgress />
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Cargando...
                </Typography>
              </Box>
            </PageBgBlock>
          ) :  loadingButton ? (<HourglassLoader/> ) :
       documentVisible && canSign ? (
            <>
               <FusHiddenPreview formData={formData} techTypes={techTypes} id={id}></FusHiddenPreview>

              <Stack justifyContent="center" spacing={2}>
                <Box>
                  <List
                    sx={{
                      backgroundColor: "#BBDEFB",
                      padding: "1rem 0",
                      borderRadius: "20px",
                      marginTop: "1rem",
                    }}
                  >
                    <ListItem key="header" sx={{ justifyContent: "center" }}>
                      <Typography variant="h1" align="center">
                        ENCUESTA DE SATISFACCIÓN EES
                      </Typography>
                    </ListItem>
                    <Divider sx={{ border: "1px solid white" }} />
                    {QUESTIONS.map((question, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          sx={{ paddingLeft: "9rem", paddingRight: "9rem" }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{ dispaly: "flex", width: "100%" }}
                          >
                            <Grid size={9}>
                              <ListItemText
                                primary={
                                  <Typography variant="body1">
                                    {question}
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid
                              size={3}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <RadioGroup
                                disabled={!showQuestions}
                                row
                                value={answers[index]}
                                onChange={(event) =>
                                  handleAnswerChange(index, event.target.value)
                                }
                              >
                                <FormControlLabel
                                  disabled={!showQuestions}
                                  value="yes"
                                  control={<Radio />}
                                  label="Sí"
                                />
                                <FormControlLabel
                                  disabled={!showQuestions}
                                  value="no"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </ListItem>
                        {index < QUESTIONS.length - 1 && (
                          <Divider sx={{ border: "1px solid white" }} />
                        )}{" "}
                      </React.Fragment>
                    ))}
                  </List>
                </Box>

                {includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) &&
                formData.estado === NO_SUBTEL ? (
                  <PageBgBlock pad="2rem 9rem">
                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      CAUSALES DE RECHAZO
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <RadioGroup
                        name="opciones rechazo"
                        value={formData.rejectOption || ""}
                      >
                        {REJECT_F.map((reject, index) => (
                          <FormControlLabel
                            key={index}
                            value={reject}
                            control={
                              <Radio onClick={() => handleClick(reject)} />
                            }
                            label={reject}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      JUSTIFICACIÓN DE SUBTEL
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <TextField
                        multiline
                        minRows={4}
                        maxRows={30}
                        id="outlined-required"
                        placeholder="Texto aquí"
                        size="small"
                        value={formData.justificacion_subtel}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            justificacion_subtel: event.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </PageBgBlock>
                ) : null}

                <Grid container spacing={2} sx={{ paddingTop: "2rem" }}>
                  {submitConfirm ? (
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size={12}
                    >
                      <Alert
                        icon={false}
                        variant="filled"
                        severity="warning"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          "& .MuiAlert-message": {
                            textAlign: "center",
                            width: "inherit",
                          },
                        }}
                      >
                        <Typography
                          variant="body"
                          color="inherit"
                          sx={{ textAlign: "center" }}
                        >
                          Revise que todos los campos contienen la información
                          correcta antes de firmar el formulario FAS. Al hacer
                          clic en 'Firmar y enviar', el documento quedará
                          firmado sin opción de modificación.
                        </Typography>
                      </Alert>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid size={2}></Grid>
                  <Grid size={4}>
                    <PageButtonWhite
                      onClick={rejectDoc}
                      loading={loadingButton ||  (isSubmitEnabled && includeRol([ROL_REP_EES, ROL_REP_EES_TEMP])) || (!formData.rejectOption && includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) )}
                      width="12rem"
                    >
                      {" "}
                      Rechazar
                    </PageButtonWhite>
                  </Grid>
                  <Grid size={4}>
                    <PageButtonBlack
                      onClick={singDoc}
                      loading={
                        !isSubmitEnabled ||
                        loadingButton || (!isSubmitEnabled && includeRol([ROL_REP_EES, ROL_REP_EES_TEMP])) ||
                        (formData.rejectOption && includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) )
                      }
                      justifyContent="center"
                      width="12rem"
                    >
                      Firmar y enviar
                    </PageButtonBlack>
                  </Grid>
                  <Grid size={2}></Grid>
                </Grid>
              </Stack>
            </>
          ) : (
            <PageError>
                  El documento al que intenta acceder no esta disponible para ser
                  firmado.
                </PageError>
          )}
        </PageBody>
        <ModalStandard
          open={open}
          error={modalError}
          title={modalContent}
          btnText="Volver a listado"
          onClick={() => navigate("/fus_lista")}
          onClose={handleClose}
        ></ModalStandard>
      </>
    );
  }
}
