import React, { useState, useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";

import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import { NumericFormat } from "react-number-format";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Alert from "@mui/material/Alert";
import { Divider } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import PageBgBlock from "../GeneralComponents/PageBgBlock";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import InputRut from "../GeneralComponents/InputRut";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import ModalStandard from "../GeneralComponents/ModalStandard";

import { useNavigate } from "react-router-dom";




function validarRut(rut) {
    rut = rut.replace(/\./g, "").replace(/-/g, "").replace(/\s+/g, '').toUpperCase();
    if (!/^\d{7,8}[0-9K]$/.test(rut)) {
        return false;
    }

    const aux = rut.slice(0, -1);
    const dv = rut.slice(-1);

    const reverted = Array.from(aux).reverse().map(Number);

    const factors = [2, 3, 4, 5, 6, 7];
    let sum = 0;

    for (let i = 0; i < reverted.length; i++) {
        sum += reverted[i] * factors[i % factors.length];
    }

    const res = (11 - (sum % 11)) % 11;

    if (res === 11) return dv === "0";
    if (res === 10) return dv === "K";
    return dv === res.toString();
}


function formatearRut(rut) {
    rut = rut.replace(/^0+/, "").replace(/\./g, "").replace(/-/g, "").replace(/\s+/g, '');
    const cuerpo = rut.slice(0, -1);
    const dv = rut.slice(-1);
    if (rut.length > 1){
      return cuerpo + "-" + dv;
    }else{
      return cuerpo+dv
    }
}


export default function ParqueAdd() {
  useAxiosPrivate();
  const { setPageTitle, setDrawerHidding, userSesion } =
    useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [techTypes, setTechTypes] = useState([]);
  const [RBDList, setRBDList] = useState([""]);
  const [submitConfirm, setSubmitConfirm] = useState(false);
  const [rbdLoading, setRbdLoading] = React.useState(false);
  const [autoCompleteInput, setAutoCompleteInput] = React.useState("");
  const fusRef = useRef();
  const autoCompleteRef = useRef(null);
  const rutRef = useRef(null);
  const evidenceRef = useRef(null);
  const [evError, setEvError] = useState(true);
  const [error, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");


  const [errorRut, setErrorRut] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);


  const [errors, setErrors] = useState({rut_rep_1: false, rut_rep_2: false});

  const [formData, setFormData] = useState({
    rut_rep_1: "",
    nombre_rep_1: "",
    rut_rep_2: "",
    nombre_rep_2: "",
    nombre: "",
    id_zona: "",
    fecha_creacion: "",
    email: "",
    latitud: "",
    longitud: "",
    tipo_establecimiento: "",
    telefono: "",
    direccion: "",
    jornada_a: false,
    jornada_b: false,
    jornada_c: false,
    jornada_d: false,

  });

    const validateForm = () => {
    const newErrors = {};
    if (
      (formData.telefono.trim() &&
            !/^\d{9}$/.test(formData.telefono)) && formData.telefono !== "NULL" && formData.telefono !== ""
    ) {
      newErrors.telefono = "Debe ser un número de teléfono válido de 9 dígitos";
    }

    if (
      formData.direccion.trim() &&
      formData.direccion.trim().length <= 10 
    ) {
      newErrors.direccion = "La dirección debe tener al menos 10 caracteres";
    }

    if (
      (!formData.email.trim() ||
            !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email))  && formData.email !== "NULL" && formData.email !== ""
    ) {
      newErrors.email = "E-mail inválido";
    }
    if (errors.rut_rep_1){
      newErrors.rut_rep_1 = "Rut inválido"
    }
    if (errors.rut_rep_2){
      newErrors.rut_rep_2 = "Rut inválido"
    }
    return newErrors;
  };

    const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

    const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };


    const handleRutChange = (e) => {
        const { name, value } = e.target;
        const isValid = validarRut(value);

        setErrors({
      ...errors,
      [name]: !isValid && value !=="",
    });

    setFormData({
      ...formData,
      [name]: formatearRut(value),
    });
    };



function getEes() {
    if (autoCompleteInput !== "") {
        const rbd_temp = autoCompleteInput;

        axios.getEesInfo(rbd_temp)
            .then((response) => {

                const updatedData = Object.fromEntries(
                    Object.entries(response.data).filter(([key]) => key !== "rbd")
                );

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ...updatedData
                }));

                setError(false);
                setErrorMsg("");
            })
            .catch((error) => {
                console.error("Error en getEes:", error);

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    nombre: "",
                    id_zona: "",
                    fecha_creacion: "",
                    email: "NULL",
                    latitud: "",
                    longitud: "",
                    tipo_establecimiento: "",
                    telefono: "NULL",
                    direccion: "",
                    jornada_a: false,
                    jornada_b: false,
                    jornada_c: false,
                    jornada_d: false,
                }));

                setError(true);
                setErrorMsg("Colegio no encontrado");
            });
    }
}

function getEes2() {
    if (autoCompleteInput !== "") {
        const rbd_temp = autoCompleteInput;
        axios.getEesData(rbd_temp, "1")
            .then((response) => {
                const tempRep =
                    response.data.nombres_representantes.length === 1
                        ? [response.data.nombres_representantes[0], "Sin representante"]
                        : response.data.nombres_representantes;

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ees_name: response.data.nombre_colegio,
                    nombre_rep_1: response.data.nombres_representantes.length > 0 ? response.data.nombres_representantes[0] : "",
                    nombre_rep_2: response.data.nombres_representantes.length > 1 ? response.data.nombres_representantes[1] : "",
                    rut_rep_1: response.data.rut_representates.length > 0 ? response.data.rut_representates[0] : "",
                    rut_rep_2: response.data.rut_representates.length > 1 ? response.data.rut_representates[1] : "",
                }));

                setError(false);
            })
            .catch((error) => {
                console.error("Error al obtener datos del EES:", error);

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    nombre_rep_1: "",
                    nombre_rep_2: "",
                    rut_rep_1: "",
                    rut_rep_2: "",
                }));

                setError(true);
            });
    }
}

    

      function getRBD() {
        if (autoCompleteInput !== "") {
            let rbd_temp = autoCompleteInput
            setRbdLoading(true)
            axios.getRBD(rbd_temp)
                .then((response) => {
                    setRBDList(response.data.rbd_list)
                    setRbdLoading(false);
                    setError(false);
                    setErrorMsg("");
                    getEes();
                    getEes2();
                })
                .catch((error) => {
                    setRbdLoading(true)
                    setError("Colegio no encontrado");
                })
        }
    }



  function handleClick(event) {
    event.preventDefault();

    setErrors(validateForm())
    if (Object.keys(errors).length > 0) {
      console.log(validateForm())
      return;
    }
      setLoading(true);
      axios
        .putEesInfo(formData.rbd, formData)
        .then((response) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
 
    
  }
  const handleSwitch = (event) => {
    setFormData({ ...formData, checked: event.target.checked });
  };
  function handleDropDown(value) {
    setFormData({ ...formData, tech_id: value });
  }

  useEffect(() => {
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      rbd: autoCompleteInput ? autoCompleteInput : "",
    });
    getRBD();
  }, [autoCompleteInput]);

  if (pageLoaded) {
    return (
      <>
        
      <PageBgBlock >
          <Stack sx={{ background: "#FFFFFF", borderRadius: "0.5rem" }}>
            <Box
              ref={fusRef}
            >
              <Stack alignItems="left" justifyContent="center" spacing={2}>
                <Typography
                  variant="h1"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
                >
                  MODIFICAR EES
                </Typography>

                <Box
                  justifyContent="center"
                  sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                >
                  <Typography
                    variant="body"
                    align="center"
                    sx={{ marginBottom: "2rem", maxWidth: "45rem" }}
                  >
                    Para <b>modificar</b> establecimientos al Estado de Parque actual llene los campos a editar, cuide que la información esté correcta antes de agregar.
                  </Typography>
                </Box>

                <form onSubmit={handleClick} id="parque_update">

                    
                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <Box ref={autoCompleteRef}>
                <FormControl sx={{ minWidth: "100%" }}>
                        <Autocomplete
                            key="rbd"
                            value={autoCompleteInput}
                            onChange={(event, newValue) => {
            setAutoCompleteInput(newValue ? newValue : "");
            setFormData({ ...formData, rbd: newValue ? newValue : "" });
        }}
                            inputValue={autoCompleteInput}
                            onInputChange={(event, newValue) => {
            setAutoCompleteInput(newValue ? newValue : "");
            setFormData({ ...formData, rbd: newValue ? newValue : "" });
        }}
                            disablePortal
                            loading={rbdLoading}
                            loadingText="Cargando..."
                            multiple={false}
                            options={RBDList}
                            //blurOnSelect
                            autoComplete
                            size='small'
                            //onBlur={getEes}
                            renderInput=
                            {
                                (params) => {
                                    return (
                                        <TextField required
                                            //onChange={(event) => handleAutoRBDchange(event, 1)}
                                            error={!!error} 
                                            helperText={errorMsg}
                                            {...params} label="RBD" />
                                    )
                                }
                            }
                        />
                    </FormControl>
                    </Box>
                      </Grid>


                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                          <InputLabel
                            id="tipo_establecimiento-label"
                            sx={{ backgroundColor: "#fff" }}
                          >
                            Tipo de establecimiento
                          </InputLabel>
                          <Select
                            labelId="severidad-label"
                            id="tipo_establecimiento"
                            name="tipo_establecimiento"
                            value={formData.tipo_establecimiento}
                            label="Tipo de establecimiento"
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={handleInputChange}
                          >

                            <MenuItem value={"Corporación Municipal"}>Corporación Municipal</MenuItem>
                            <MenuItem value={"SLE"}>SLE</MenuItem>
                          </Select>
                        </FormControl>
                    </Grid>

                    <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                            required
                          id="outlined-required"
                          label="Nombre"
                          name="nombre"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.nombre}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                            required
                          id="outlined-required"
                          label="Dirección"
                          size="small"
                          name="direccion"
                          sx={{ width: "100%" }}
                          value={formData.direccion}
                      error={!!errors.direccion}
                      helperText={errors.direccion}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>

                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                                required
                                id="outlined-required"
                                label="RUT Representante EES 1"
                                size="small"
                                name="rut_rep_1"
                                helperText={errors.rut_rep_1 ? "RUT inválido" : "(12345678-9)"}
                                error={!!errors.rut_rep_1} 
                                value={formData.rut_rep_1}
                                sx={{
                                    width: "100%",
                                }}
                                onChange={handleRutChange}
                            />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                                
                                id="outlined-required"
                                label="RUT Representante EES 2"
                                size="small"
                                name="rut_rep_2"
                                helperText={errors.rut_rep_2 ? "RUT inválido" : "(12345678-9)"}
                                error={!!errors.rut_rep_2} 
                                value={formData.rut_rep_2}
                                sx={{
                                    width: "100%",
                                }}
                                onChange={handleRutChange}
                            />
                        </FormControl>
                      </Grid>

                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                        required
                          id="outlined-required"
                          label="Nombre Representante EES 1"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.nombre_rep_1}
                          name="nombre_rep_1"
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          required={formData.rut_rep_2 !==""}
                          id="outlined-required"
                          label="Nombre Representante EES 2"
                          size="small"
                          sx={{ width: "100%" }}
                          name="nombre_rep_2"
                          value={formData.nombre_rep_2}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="telefono"
                      label="Teléfono"
                      size="small"
                      value={formData.telefono}
                      onChange={handleInputChange}
                      error={!!errors.telefono}
                      helperText={errors.telefono}
                    />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="email"
                      label="E-mail"
                      size="small"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </FormControl>
                </Grid>
                      <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                        <TextField
                        disabled
                      name="latitud"
                      label="Latitud"
                      size="small"
                      value={formData.latitud}
                    />
                  </FormControl>
                      </Grid>
                      <Grid size={6}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                        disabled
                      name="longitud"
                      label="Longitud"
                      size="small"
                      value={formData.longitud}
                    />
                  </FormControl>
                      </Grid>

                      <Grid size={6}>

                  <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                            disabled
                          id="id_zona"
                          label="ID Zona"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.id_zona}
                        />
                        </FormControl>

              </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                        disabled
                      name="fecha_creacion"
                      label="Fecha de ingreso"
                      size="small"
                      value={formData.fecha_creacion}
                    />
                  </FormControl>
                </Grid>

         <Grid size={4}>

                  <Typography
                    variant="body"
                  >
            Tipo de jornada:
            </Typography>
         </Grid>       

        <Grid size={2}>
          <FormControlLabel 
            control={
              <Checkbox checked={formData.jornada_a} onChange={handleCheckboxChange} name="jornada_a" />
            }
            label="A"
          />

          </Grid>
        <Grid size={2}>
          <FormControlLabel  
            control={
              <Checkbox checked={formData.jornada_b} onChange={handleCheckboxChange} name="jornada_b" />
            }
            label="B"
          />
          </Grid>
        <Grid size={2}>
          <FormControlLabel
            control={
              <Checkbox checked={formData.jornada_c} onChange={handleCheckboxChange} name="jornada_c" />
            }
            label="C"
          />
          </Grid>
        <Grid size={2}>
          <FormControlLabel
            control={
              <Checkbox checked={formData.jornada_d} onChange={handleCheckboxChange} name="jornada_d" />
            }
            label="D"
          />
          </Grid>




                    </Grid>



          <Box sx={{margin: "2rem auto 0"}}>
            <PageButtonSimple
              width="30rem"
                        form="parque_update"
                        startIcon={<ModeEditOutlineOutlinedIcon/>}
                        loading={loading}
                        disabled={autoCompleteInput === "" || error}
                      >
                        Modificar establecimiento (Alta EES)
                      </PageButtonSimple>
          </Box>

                </form>
              </Stack>
            </Box>
          </Stack>
        </PageBgBlock>

      </>
    );
  } else {
    return <Box></Box>;
  }
}
