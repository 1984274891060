import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";

import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CircularProgress } from '@mui/material';
import ListStepper from "../GeneralComponents/ListStepper";
import ListEmpty from "../GeneralComponents/ListEmpty";

import PrefacturaOpCard from "./PrefacturaOpCard";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {NO_SUBTEL, NO_MINEDUC, REJECTED, APROVED, ROL_ADMIN, ROL_OP_ADMIN, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_CON_MINEDUC, ROL_VAL_MINEDUC, PERIODS} from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);





export default function PrefacturaDetails() {
  useAxiosPrivate();
  const { userSesion } = useContext(GeneralContext);
  const [rows, setRows] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState(rows);
  const [loadingTable, setLoadingTable] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [operadorId, setOperadorId] = useState(0);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

    const [tableDataTest, setTableDataTest] =  useState([{
    "":"",
    "id":"1",
    "diff":"CON DIFERENCIAS",
    "color": "#C489D9",
    "diff_action": "",
    "estado_validacion":"En Apelación",
    "id_operador":"test",
    "periodo_facturacion":"2024-12",
    "details": [{"zona": "ZONA01", "Etapas": [{"etapa":"Etapa 1", "bloques":[{"nombre": "01", "diff": true},{"nombre": "02", "diff": true}]},{"etapa":"Etapa 2", "bloques":[{"nombre": "01", "diff": false},{"nombre": "02", "diff": true},{"nombre": "03", "diff": false}]}]},
      {"zona": "4", "Etapas": [{"etapa":"Etapa 1", "bloques":[{"nombre": "01", "diff": true},{"nombre": "02", "diff": true}]},{"etapa":"Etapa 2", "bloques":[{"nombre": "01", "diff": true},{"nombre": "02", "diff": true}]}]}]
  }]);

  const canSign = (estado) => {
  	if (
      estado === NO_SUBTEL &&
      includeRol([ROL_VAL_SUBTEL])
    ) 

    return false;
  };


    const downloadPDF = (url) => {
      setLoadingPDF(true);
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.href = blobUrl;
                a.download = 'download.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
                setLoadingPDF(false);
            })
            .catch((error) => {setLoadingPDF(false); console.error('Error al descargar el archivo:', error); window.open(url, '_blank'); });
    };


async function getOperator() {
  try {
    const response = await axios.getOperator(userSesion.rut);
    const operadorId = response.data.id_operador;
    return operadorId;
  } catch (error) {
    console.error("Error al obtener el operador:", error);
    throw error;
  }
}
  const fetchData = async (periodo) => {
  setLoadingTable(true);

  try {
    let operador = id; 
    if (!operador) {
      operador = await getOperator();
      setOperadorId(operador);
    }

    console.log("Operador ID:", operador);

    // Llamar al endpoint con el operador obtenido
    const response = await axios.getPrefacturaByOperador(operador, periodo);
    const modifiedData = response.data.map((row) => ({
      ...row,
      diff: row["diferencia"] ? "CON DIFERENCIAS" : "SIN DIFERENCIAS",
      color: row["diferencia"] ? "#C489D9" : "#E6E6E6",
      codigo: row["codigo prefactura"] || "-",
      periodo: row.periodo.slice(0, 7),
      estado_validacion: row["estado validacion"],
      puede_firmar: canSign(row.estado),
    }));

    setRows(modifiedData);
    setTableData(modifiedData);
    console.log(modifiedData);
  } catch (error) {
    console.error("Error en fetchData:", error);
    setRows([])
    setTableData([])
  } finally {
    setLoadingTable(false);
  }
};

  useEffect(() => {
    fetchData(PERIODS[0]);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const refreshData = (name: String, value: any) => {
    if(value){
    fetchData(value);
  }
  };


  const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;
    setFilters(filterObj);
    if (value == null) {
      delete filterObj[name];
    }
    if (Object.keys(filterObj).length !== 0) {
      setTableData([
        ...tableData.filter((item) =>
          Object.entries(filterObj).every(([key, value]) => item[key] === value)
        ),
      ]);
    } else {
      setTableData(rows);
    }
  };


  useEffect(() => {
    setPageTitle("LISTADO APELACIONES PRE-FACTURA");
  }, [pageTitle, setPageTitle]);

  return (

    <PageBody>
      <Box width="100%">
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: "28px", marginTop: "-2rem" }}
          >

            <Grid size={3}>
          <InputFilter
            opt={PERIODS}
            data={rows}
            value={PERIODS[0]}
            item={"periodo"}
            name={"Periodo"}
            callback={refreshData}
          />
        </Grid>
          </Grid>
        



        {loadingTable ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
            :
        <PageBgBlock pad="0">
          {tableData.length === 0 ? (<ListEmpty msg="No hay prefacturas registradas para este periodo."/>) : null}
                      <Box display="flex" flexWrap="wrap" justifyContent="left">
            {tableData
              .map((data, index) => (
                <PrefacturaOpCard key={index} data={data} dataDev={null} fetch={fetchData}/>
              ))}
          </Box>
        </PageBgBlock>

          }
       
      </Box>
    </PageBody>
  );
}
