import React, { useState, useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import "dayjs/locale/es";
import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress, Divider, FormControl, TextField } from '@mui/material';
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import ContingenciasHiddenPreview from "./ContingenciasHiddenPreview";
import PageError from "../GeneralComponents/PageError";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ContingenciasPreview() {
  useAxiosPrivate();
  const { userSesion, setPageTitle, setDrawerHidding } = useContext(GeneralContext);


  //const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [documentVisible, setDocumentVisible] = useState(true);

  const [formData, setFormData] = useState({
    name_emisor: userSesion.nombre,
    rut_emisor: userSesion.rut,
    nombre_colegio: "",
    id_operador: "",
    id_zona: "",
    operator_name: "", 
    checked: false,
    ees_temporal_manager: "",
    fecha_hora_ocurrencia: dayjs(),
    fecha_hora_solucion: dayjs(),
    fecha_hora_inicio: dayjs(),
    fecha_hora_termino: dayjs(),
    codigo_tipo_evento: "",
    origen: "",
    extension_falla: "",
    severidad: "",
    rbd: "",
    texto_falla_operador: "",
    evidence: "",
    days: [],
    texto_contingencia_pgr: "",
    repetir: null,
  });


    const links = [
    { name: 'Inicio', path: '/home' },
    { name: 'Listado Contingencias', path: '/contingencias_lista' },
    { name: 'Firma Contingencia', path: '/contingencias_firma/'+id },
  ];


    const fetchData = async (query) => {
    setLoading(true);
    try {
    const response = await axios.getContingencia(id);
    setFormData({
      ...response.data.datos,
      fecha_hora_ocurrencia: dayjs(response.data.datos['fecha_hora_ocurrencia'] || ""),
      fecha_hora_solucion: dayjs(response.data.datos['fecha_hora_solucion'] || ""),
      fecha_hora_inicio: dayjs(response.data.datos['fecha_hora_inicio'] || ""),
      fecha_hora_termino: dayjs(response.data.datos['fecha_hora_termino'] || ""),
      estado: response.data.estado,
    });
  } catch (error) {
    console.error("Error al obtener los datos:", error);
    setDocumentVisible(false);
  } finally {
    setLoading(false);
  }
  };

  useEffect(() => {
    fetchData("");
  }, []);

      useEffect(() => {
        fetchData()
        setDrawerHidding(false)
    }, [setPageTitle, setDrawerHidding]);

  return (
    <PageBody>
          <BreadcrumbStandard links={links} />
      <PageBgBlock pad="0" >
      {loading ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
            :
             documentVisible ? 
             (

      <PageBgBlock pad="2rem 9rem" >
      <>
             <ContingenciasHiddenPreview formData={formData} id={id}></ContingenciasHiddenPreview>
             <Divider sx={{ my: 2 }} />

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      JUSTIFICACIÓN DE SUBTEL
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <TextField
                        disabled
                        multiline
                        minRows={4}
                        maxRows={30}
                        id="outlined-required"
                        placeholder="Texto aquí"
                        size="small"
                        value={formData.justificacion_subtel || ""}
                      />
                    </FormControl>
             </>
             </PageBgBlock>) : (
            <PageError>
                  El documento al que intenta acceder no esta disponible.
                </PageError>
          )
        }
      </PageBgBlock>

    </PageBody>
  );
}
