import React, { useState, useContext } from "react";
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import { TextField, FormControl, Box, Typography } from "@mui/material";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import InputFile from "../GeneralComponents/InputFile";

export default function UserConf() {
  useAxiosPrivate();
  const { userSesion, setUserSesion } = useContext(GeneralContext);
  //const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    nombre: userSesion.nombre,
    rut: userSesion.rut,
    telefono: userSesion.telefono,
    email: userSesion.email,
    direccion: userSesion.direccion,
  });

  const [sign, setSign] = useState(null);
  const [errors, setErrors] = useState({});

  const save = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    putNewInfo();
    putNewSign();
  };
  const setFile = (archivo) => {
    setSign((prevData) => ({
      ...prevData,
      archivo,
    }));
  };


  const validateForm = () => {
    const newErrors = {};
    if (!formData.nombre.trim()) {
      newErrors.nombre = "El nombre es requerido";
    }

    if (!formData.rut.trim()) {
      newErrors.rut = "El RUT es requerido";
    }

    if (
      formData.telefono.trim() &&
      !/^\d{9}$/.test(formData.telefono)
    ) {
      newErrors.telefono = "Debe ser un número de teléfono válido de 9 dígitos";
    }

    if (
      formData.direccion.trim() &&
      formData.direccion.trim().length <= 10 
    ) {
      newErrors.direccion = "La dirección debe tener al menos 10 caracteres";
    }

    if (
      !formData.email.trim() ||
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)
    ) {
      newErrors.email = "Correo electrónico inválido";
    }

    return newErrors;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const putNewInfo = () => {
    setLoading(true);
    const queryString = new URLSearchParams(formData).toString();
    axios
      .putConfigUser(userSesion.rut, queryString)
      .then((response) => {
        setUserSesion((prevValues) => ({
                                ...prevValues,
                                telefono: formData.telefono,
                                email: formData.email,
                                direccion: formData.direccion,
                              }));
        console.log(userSesion);

      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const putNewSign = () => {
    if (sign && sign.archivo) {
      setLoading(true);
      const form = new FormData();
      form.append("file", sign.archivo);

      axios
        .putConfigUserSign(userSesion.rut, form)
        .then((response) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <PageBody>
      <PageBgBlock pad="55px 150px">
        <form onSubmit={save}>
          <Box sx={{marginBottom: '18px'}}>
            <Typography
              variant="h1"
              sx={{ textAlign: "left", marginBottom: "5px" }}
            >
              CONFIGURACIÓN USUARIO
            </Typography>

            <Box
              justifyContent="center"
              sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
            >
              <Typography
                variant="body" align="left"
                sx={{ marginBottom: "40px" }}
              >
                En este formulario podrá ver su nombre y RUT en campos
                inamovibles puesto que traemos esa información desde su Clave
                Única, aún asi lo invitamos a llenar los campos de teléfono,
                correo electrónico, dirección e imagen de firma (todo de manera
                voluntaria)
              </Typography>
            </Box>

            <Stack spacing={4} sx={{ marginBottom: "10px" }}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  disabled
                  required
                  name="nombre"
                  label="Nombre y Apellido"
                  size="small"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  error={!!errors.nombre}
                  helperText={errors.nombre}
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      disabled
                      required
                      name="rut"
                      label="Rut Usuario"
                      size="small"
                      value={formData.rut}
                      onChange={handleInputChange}
                      error={!!errors.rut}
                      helperText={errors.rut}
                    />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="telefono"
                      label="Teléfono"
                      size="small"
                      value={formData.telefono}
                      onChange={handleInputChange}
                      error={!!errors.telefono}
                      helperText={errors.telefono}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      required
                      name="email"
                      label="Correo electrónico"
                      size="small"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="direccion"
                      label="Dirección"
                      size="small"
                      value={formData.direccion}
                      onChange={handleInputChange}
                      error={!!errors.direccion}
                      helperText={errors.direccion}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <InputFile onFileSelect={setFile} />
            </Stack>

            <Typography
              variant="small" align="left"
              sx={{ marginBottom: "18px" }}
            >
              (Archivos en JPG o PNG / Máximo 256x256 pixeles)
            </Typography>
          </Box>

          <Box
            justifyContent="center"
            sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
          >

          </Box>

          <PageButtonBlack loading={loading}> Guardar</PageButtonBlack>
        </form>
      </PageBgBlock>
    </PageBody>
  );
}
