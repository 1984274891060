import React, { useState, useContext, useRef } from "react";
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Box,
  Typography,
  Button,
  Paper,
  Divider,
  TextField,
  FormControl,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Switch,
  Alert,
} from "@mui/material";

import Grid from "@mui/material/Grid2";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";

import InputFile from "../GeneralComponents/InputFile";

import HourglassLoader from "../GeneralComponents/HourglassLoader";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function CheckFiles() {
  useAxiosPrivate();
  const { userSesion, setPageTitle, setDrawerHidding } =
    useContext(GeneralContext);

  const [loading, setLoading] = useState(false);

  const evidenceRef = useRef(null);
  const [evError, setEvError] = useState(false);
  const [evidence, setEvidence] = useState();
  const [reqError, setReqError] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const [showAll, setShowAll] = useState(false);
  const [formData, setFormData] = useState({
    fecha_creacion: "",
    id: "",
    tipo: "",
    firmas: []
  });


const filteredFirmas = showAll
  ? formData.firmas
  : formData.tipo === "Apelacion"
  ? formData.firmas.filter((row) => row.valido )
  : formData.firmas.filter((row) => row.firma_fechada);

  const setFile = (archivo) => {
    setEvError(!archivo ? true : false);
    setEvidence((prevData) => ({
      ...prevData,
      archivo,
    }));
  };


  const save = async (event) => {
    event.preventDefault();
    if (!evidence) {
      setEvError(!evidence ? true : false);
      evidenceRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    const form = new FormData();
    form.append("file", evidence.archivo);
    setLoading(true);
    

    axios
      .checkSignature(form)
      .then((response) => {
        setFormData(response.data);
        setReqError(false);
        setShowForm(false)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setReqError(true);
      });
  };

  return (
    <>
      <PageBody>
      {showForm ? (
        <PageBgBlock pad="0">
          {loading ? (
            <HourglassLoader />
          ) : (
            <>
              <Box
                sx={{
                  paddingLeft: "9rem",
                  paddingRight: "9rem",
                  paddingTop: "2rem",
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{ marginBottom: "0.24rem" }}
                >
                  VERIFICADOR DE DOCUMENTOS FIRMADOS
                </Typography>
                <Box
                  justifyContent="center"
                  sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                >
                  <Typography
                    variant="body"
                    align="center"
                    sx={{ marginBottom: "2rem" }}
                  >
                    ...
                  </Typography>
                </Box>

                <form onSubmit={save} id="document">
                  <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
                    <Typography
                      variant="h3"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      DOCUMENTO SAGEC
                    </Typography>

                    <Box ref={evidenceRef}>
                      <InputFile
                        onFileSelect={setFile}
                        fileType="pdf"
                        placeholder="Subir evidencia (formato PDF)"
                      />
                      <Typography
                        variant="small"
                        align="left"
                        sx={{
                          marginBottom: "18px",
                          color: evError ? "red" : "#111",
                        }}
                      >
                        {evError
                          ? "Debe adjuntar evidencia de la instalación"
                          : "Máximo 25mb"}
                      </Typography>
                    </Box>
                  </Stack>
                </form>
              </Box>
              <Stack
                sx={{
                  paddingLeft: "9rem",
                  paddingRight: "9rem",
                  paddingBottom: "3rem",
                }}
              >
                <PageButtonBlack form="document" loading={loading}>
                  Verificar documento
                </PageButtonBlack>
              </Stack>
            </>
          )}
        </PageBgBlock>) : (
        <PageBgBlock>
          {!reqError ? (
            <>
              <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
                <Typography
                  variant="h3"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  INFORMACIÓN DEL DOCUMENTO
                </Typography>

                <Alert
                  variant="filled"
                  severity={formData.valido ? "success" : "error"}
                >
                  <Typography
                    variant="body"
                    color="inherit"
                    sx={{ textAlign: "center" }}
                  >
                    {formData.valido
                      ? "El documento ingresado es válido"
                      : "El documento es ingresado no es válido"}
                  </Typography>
                </Alert>

                <Grid container spacing={2}>
                  <Grid size={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        disabled
                        required
                        name="tipo"
                        label="Tipo de documento"
                        size="small"
                        value={formData.tipo}
                      />
                    </FormControl>
                  </Grid>
                  <Grid size={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        disabled
                        required
                        name="fecha_creacion"
                        label="Fecha de creación documento"
                        size="small"
                        value={formData.fecha_creacion}
                      />
                    </FormControl>
                  </Grid>
                  <Grid size={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        disabled
                        required
                        name="id"
                        label="ID"
                        size="small"
                        value={formData.id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Stack>

              <Typography
        variant="h3"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        RESUMEN DE FIRMAS
      </Typography>

      {/* Switch para alternar entre todos o filtrados */}
      <FormControlLabel
        control={
          <Switch
            checked={showAll}
            onChange={(e) => setShowAll(e.target.checked)}
            color="primary"
          />
        }
        label={showAll ? "Mostrar todos los registros" : "Mostrar solo firmados"}
        sx={{ mt: 2, mb: 2 }}
      />

      <TableContainer sx={{ mb: 2, mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>RUT</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Rol</TableCell>
              <TableCell>Fecha firma</TableCell>
              <TableCell>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFirmas.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ verticalAlign: "top" }}>
                  {row.rut}
                </TableCell>
                <TableCell sx={{ verticalAlign: "top" }}>
                  {row.nombre}
                </TableCell>
                <TableCell sx={{ verticalAlign: "top" }}>
                  {row.rol_firma}
                </TableCell>
                <TableCell sx={{ verticalAlign: "top" }}>
                  {row.firma_fechada}
                </TableCell>
                <TableCell sx={{ verticalAlign: "top" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "no-wrap",
                    }}
                  >
                    <FiberManualRecordIcon
                      sx={{
                        fontSize: "0.75rem",
                        color: row.valido
                          ? "#41AE43"
                          : row.firma_fechada && formData.tipo !== "Apelacion"
                          ? "#FB3B3B"
                          : "grey",
                        marginRight: "0.5rem",
                      }}
                    />
                    {row.valido
                      ? "Firma válida"
                      : row.firma_fechada && formData.tipo !== "Apelacion"
                      ? "Firma inválida"
                      : "No firma"}
                    <Box sx={{ flexGrow: 1 }} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PageButtonBlack onClick={() => setShowForm(true)} loading={loading}>
                  Verificar otro documento
                </PageButtonBlack>
            </>
          ) : (
            <Alert variant="filled" severity="error">
              <Typography
                variant="body"
                color="inherit"
                sx={{ textAlign: "center" }}
              >
                El documento ingresado no pudo ser válidado
              </Typography>
            </Alert>
          )}
        </PageBgBlock>)}
      </PageBody>
    </>
  );
}
