import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Paper, Checkbox } from "@mui/material";
import Grid from '@mui/material/Grid2';

import { useNavigate } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CircularProgress } from '@mui/material';
import ListEmpty from "../GeneralComponents/ListEmpty";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";

import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import { Download as DownloadIcon } from "@mui/icons-material";


import {APROVED, } from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const columns = [
  {
    id: "checkbox",
    label: "",
    width: "5%",
    minWidth: "50px",
    align: "left",
  },
{ id: "folio", label: "ID folio", width: "12%", minWidth: "100px"},
  { id: "periodo", label: "Periodo", width: "12%", minWidth: "120px"},
  { id: "rut_emisor", label: "Rut Emisor", width: "12%", minWidth: "120px" },
  {
    id: "estado_validacion",
    label: "Estado",
    width: "16%",
    minWidth: "160px",
    align: "left",
  },
  {
    id: "firma",
    label: "Firma",
    width: "12%",
    minWidth: "100px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "codigo",
    label: "Código",
    width: "12%",
    minWidth: "100px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "link_prefactura_sagec",
    label: "Prefactura SAGEC",
    width: "10%",
    minWidth: "100px",
    align: "center",
  },
  {
    id: "link_prefactura_operador",
    label: "Prefactura Operador",
    width: "10%",
    minWidth: "100px",
    align: "center",
  },
  {
    id: "nombre_operador",
    label: "Operador",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
];


export default function PrefacturaHistory({ prefilter = false }) {
  useAxiosPrivate();
  const [rows, setRows] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState(rows);
  const [loadingTable, setLoadingTable] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [selected, setSelected] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const navigate = useNavigate();



    const downloadPDF = (url) => {
    setLoadingPDF(true);
    window.open(url, '_blank');
  };

    const downloadZip = async () => {
      try {
        if (selected.length === 0){
          return
        }
        setDownloadLoader(true);
        const now = new Date();
        const timestamp = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}_${String(now.getHours()).padStart(2, "0")}-${String(now.getMinutes()).padStart(2, "0")}-${String(now.getSeconds()).padStart(2, "0")}`;
        
        const response = await axios.downloadDocuments("prefactura", selected);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `prefacturas_${timestamp}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error("Error al descargar documentos:", error);
    }
    setDownloadLoader(false);
  };


 const fetchData = async (query) => {
    setLoadingTable(true);
    axios
      .getPrefacturasCompletas()
      .then((response) => {
        const modifiedData = response.data.map((row, index) => {
        //const modifiedData = testData.map((row, index) => {
          return {
            ...row,

            codigo: row["codigo prefactura"] ? row["codigo prefactura"] : "-",
            diff_action: "",
            periodo: row.periodo.slice(0, 7),
            fecha_prefactura: row.periodo.slice(0, 10),
            estado_validacion: row["estado validacion"] ? row["estado validacion"] : "Pendiente",
            index: index + 1,
            folio: String(row.id).padStart(8, '0')

            
          };
        });
        setRows(modifiedData);
        setTableData(modifiedData);
      })
      .catch((error) => {
      })
      .finally(() => {


        setLoadingTable(false);
      });

   const columnOrder = [
            "checkbox",
            "periodo",
            "codigo",
            "nombre_operador",
            "estado_validacion",
            "link_prefactura_sagec",
            "link_prefactura_operador",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;

    if (value == null) {
        delete filterObj[name];
    }

    const newTableData = Object.keys(filterObj).length !== 0
        ? rows.filter((item) =>
            Object.entries(filterObj).every(([key, value]) => item[key] === value)
        )
        : rows;

    if (JSON.stringify(newTableData) !== JSON.stringify(tableData)) {
        setSelected([]);
    }

    setFilters(filterObj);
    setTableData(newTableData);
};


    const handleSelectAll = (event) => {
  if (event.target.checked) {
    const newSelecteds = tableData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .filter((n) => n.estado_validacion.startsWith(APROVED)) 
      .map((n) => n.id); 
    setSelected(newSelecteds);
  } else {
    setSelected([]);
  }
};

  const handleCheckboxClick = (index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (index) => selected.indexOf(index) !== -1;



  useEffect(() => {
    setPageTitle("Listado histórico de pre-facturas");
  }, [pageTitle, setPageTitle]);

  function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {}, []);



  const isItemSelected = isSelected(row.id);
    return (
      <React.Fragment>
        <TableRow onClick={() => setOpen(!open)} 
          hover
          role="checkbox"
          tabIndex={-1}
          key={"TR" + index}
          aria-checked={isItemSelected}
          selected={isItemSelected}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >

          {filteredColumns.map((column, index) => {
            const value = row[column.id];
            return (
              <TableCell key={"TC" + index} align={column.align}>
                {column.id.startsWith("link_prefactura") ? (
                  


                   <PageButtonSimple
                   disabled={!value}
          width="10rem"
          pad="0.5rem"
          fs="0.6875rem"
          startIcon={<DownloadIcon />}
          onClick={(e) => {
            e.stopPropagation();
            downloadPDF(value);
          }}
        >
          {column.id.endsWith("_operador") ? (
            <Stack>
              <span>Operador prefactura</span>
              <span>{row['fecha_prefactura'] || ""}</span>
            </Stack>
          ) : (
            <Stack>
              <span>Operador prefactura</span>
              <span>
                <b>{row['version'] ? `V${row['version']}` : ""}</b> - {row['fecha_prefactura']}
              </span>
            </Stack>
          )}
        </PageButtonSimple>
            

                ) : column.id === "checkbox" ? (
                                    <Checkbox
                                    disabled={!row.estado_validacion.startsWith(APROVED)}
                                      color="primary"
                                      checked={isItemSelected}
                                      onClick={() =>
                                        handleCheckboxClick(row.id)
                                      }
                                      inputProps={{
                                        "aria-labelledby": row.id,
                                      }}
                                    />
                                  ) :

                column.id === 'estado_validacion' ? (
                
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'no-wrap',
                    }}>
                      <FiberManualRecordIcon sx={{ fontSize: "0.75rem", color:
              row['estado_validacion'] === "En Apelación"
              ? "#F9961E" : row['estado_validacion'] === "Pendiente"
                ? "#FFE263"
                : row['estado_validacion'].startsWith("Rechazado")
                ? "#FB3B3B"
                : "#41AE43" , marginRight: "0.5rem" }} />

                      {value}
                    </div>

                )
                :
                 (
                  value
                )}

                
              </TableCell>
            );
          })}
        </TableRow>
       
      </React.Fragment>
    );
  }

  return (
    <PageBody>
      <Box width="100%">
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: "28px", marginTop: "-2rem" }}
          >
            <Grid size={3}>
              <InputFilter
                data={rows}
                item={"periodo"}
                name={"Periodo"}
                callback={filterRecords}
              />
            </Grid>
            <Grid size={3}>
              <InputFilter
                data={rows}
                item={"estado_validacion"}
                name={"Estado"}
                callback={filterRecords}
              />
            </Grid>
            <Grid size={3}>
              <InputFilter
                data={rows}
                item={"nombre_operador"}
                name={"Operador"}
                callback={filterRecords}
              />
            </Grid>
            
          </Grid>
     

        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            background: "#FFFFFF",
            borderRadius: "0.25rem",
            marginBottom: "0.75rem",
          }}
        >
          <Typography
            variant="h1"
            color="inherit"
            noWrap
            sx={{ textAlign: "center", padding: "0.5rem" }}
          >
            LISTADO HISTÓRICO PRE-FACTURAS
          </Typography>
        </Paper>


        {loadingTable ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
            :
          <>
        <PageBgBlock pad="0">
          <Paper
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              background: "#FFFFFF",
              borderRadius: "0.25rem",
              marginBottom: "0.75rem",
            }}
          >
            <TableContainer>
              <Table size="small" aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {filteredColumns.map((column, index) => (
                      <TableCell
                        key={"CL" + index}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id === "checkbox" ? (
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < tableData.filter((n) => n.estado_validacion.startsWith(APROVED)).length
                                }
                                checked={
                                  selected.length > 0 &&
                                  selected.length ===  tableData.filter((n) => n.estado_validacion.startsWith(APROVED)).length
                                }
                                onChange={handleSelectAll}

                                inputProps={{ "aria-label": "select all" }}
                              />

                              {column.label}
                            </Box>
                          ) : (
                            column.label
                          )}

                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <Row key={index} row={row} index={index} />
                    ))}
                </TableBody>
              </Table>
                    {tableData.length === 0 ? (<ListEmpty></ListEmpty>) : null}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>




        </PageBgBlock>
          <Grid container >
          <Grid size={7}>
           
          </Grid>
          <Grid size={5}>
            <PageButtonWhite
              loading={downloadLoader || selected.length === 0}
              onClick={downloadZip}
              width="100%"
            >
              Descarga selección de documentos (.zip)
            </PageButtonWhite>
          </Grid>
          </Grid>
          </>

          }
       
      </Box>
    </PageBody>
  );
}
